import React from 'react'
import Banner from '../pics/Banner_Cyber_November_Remote.jpg'

export default function SpecialOffer({url}) {
    return (
        <section>
            <article className="transparentContainer flex1200">
            <img
                className="flex1200 offsetImage"
                src={Banner}
                alt="Online JavaScript Full-Stack Bootcamp in Barcelona Code School"
            />
                <h2 style={styles.topMargin}>Limited offer till November 21</h2>
                <div className='whiteCard' style={styles.button}>
                    <p></p>
                    <a href={url} target='_blank' rel='noopener noreferrer'><button >Apply now</button></a>
                </div>
            </article>
        </section>
    )
}
const styles = {
    button: {
        position: 'sticky',
        top:0
    },
    topMargin: {
        marginTop: '1rem'
    }
}
